import React, { useState } from "react";
import "./App.css";
import Carousel from "react-bootstrap/Carousel";

import logo from "./assets/logo/Web/Rubys-Tuesday-Logo-Web.png";
import FBlogo from "./assets/Facebook-Color-150.png";
import InstaLogo from "./assets/Instagram-Color-150.png";

import Image1 from "./assets/content/Image1.jpeg";
import Image2 from "./assets/content/Image2.jpeg";
import Image3 from "./assets/content/Image3.jpeg";
// import Image4 from "./assets/content/Image4.jpeg";
import Image5 from "./assets/content/Image5.jpeg";

const content = [Image1, Image2, Image3, Image5];

const App = (props) => {
  return (
    <div class="container">
      <div class="banner">
        <div class="header row d-flex justify-content-center">
          <div class="col-md-8 d-flex justify-content-center">
            <img src={logo} style={{ width: 175, height: 175 }} />
          </div>
        </div>

        <div class="row ivory d-flex justify-content-space-evenly">
          <div class="col-md-6 pad-col d-flex justify-content-center">
            <Carousel controls={false} indicators={false}>
              {content.map((cImg, idx) => (
                <Carousel.Item>
                  <div class="d-flex justify-content-center">
                    <img class="caro-image" src={cImg} key={idx} />
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
          </div>

          <div class="col-md-6 d-flex justify-content-center flex-column ivory pad-col pad-text">
            <h2 class="headerText">Welcome to Ruby's Tuesday</h2>
            <p class="bodyText">
              Ruby’s Tuesday Baking and Treats has been in the making since
              2017.
            </p>
            <p class="bodyText">
              Slowly but surely my dream of being able to sell my baking to
              others has become a reality.
            </p>
            <h2 class="headerText"> Who is Ruby?</h2>
            <p class="bodyText">
              {" "}
              This is one of our most commonly asked questions. Ruby is our
              gorgeous girl, twin of our lovely Jack who was born on a Tuesday
              in October 2012 and sadly did not make it Earth side. Her memory
              lives on in something that I am passionate about and get great
              enjoyment from.{" "}
            </p>
            <p class="bodyText">
              {" "}
              Currently I am baking from our home in Mt Albert. The kitchen is
              approved and has an ‘A’ rating from the Auckland Council. I bake
              to order so please get in touch to discuss what you might like. If
              you need some inspiration, head to my facebook or Instagram page.
              We also pop up at the odd market from time to time so keep an eye
              out.
            </p>
          </div>
        </div>

        <div class="footer row d-flex justify-content-center">
          <div class="col col-md-12 d-flex justify-content-center align-items-center pad-col">
            <p class="bodyText pad-top">
              Leisa Donne | 021 180 3602 |{" "}
              <a class="email" href={"mailto:leisa@rubystuesday.co.nz"}>
                leisa@rubystuesday.co.nz
              </a>
            </p>
          </div>
          <div class="row padding-bottom d-flex justify-content-center">
            <div class="col col-md-1 d-flex flex-column justify-content-center align-items-center">
              <div class="link">
                <a
                  class="touch"
                  href="https://www.facebook.com/Rubys-Tuesday-Baking-and-Treats-105148924255432/"
                >
                  <img src={FBlogo} class="logo pad-top" />
                </a>
              </div>
            </div>
            <div class="col col-md-1 d-flex flex-column justify-content-center align-items-center">
              <div class="link">
                <a
                  class="touch"
                  href="https://www.instagram.com/rubystuesdaybaking/"
                >
                  <img src={InstaLogo} class="logo pad-top" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="row d-flex justify-content-center">
          <div class="col-md-8 d-flex justify-content-center">
            <p class="copy">Copyright 2020 Sam Madden-Hart</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
